import React, { useEffect, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Image = ({ image, width, height, relative }) => {
  const [windowSize, setWindowSize] = useState([null, null])
  const threshold = 1000
  const relativeVal = relative ? relative : 1

  useEffect(() => {
    setWindowSize([
      window.innerHeight * relativeVal,
      window.innerWidth * relativeVal,
    ])
  }, [])

  // console.log('windowSize', windowSize)

  if (height === "full" && !width) {
    return (
      <LazyLoadImage
        threshold={threshold}
        effect="blur"
        alt={image?.alt ?? "Ocafi"}
        srcSet={`
          ${image?.url}&fit=crop&dpr=1&h=${windowSize[0]} 1x,
          ${image?.url}&fit=crop&dpr=2&h=${windowSize[0]} 2x,
          ${image?.url}&fit=crop&dpr=3&h=${windowSize[0]} 3x,
          ${image?.url}&fit=crop&dpr=4&h=${windowSize[0]} 4x
        `}
        src={image?.url}
        placeholderSrc={`${image?.url}&fit=crop&dpr=1&w=10`}
      />
    )
  } else if (width === "full") {
    return (
      <LazyLoadImage
        threshold={threshold}
        effect="blur"
        alt={image?.alt ?? "Ocafi"}
        srcSet={`
          ${image?.url}&fit=crop&dpr=1&w=${windowSize[1]} 1x,
          ${image?.url}&fit=crop&dpr=2&w=${windowSize[1]} 2x,
          ${image?.url}&fit=crop&dpr=3&w=${windowSize[1]} 3x,
          ${image?.url}&fit=crop&dpr=4&w=${windowSize[1]} 4x
        `}
        src={image?.url}
        placeholderSrc={`${image?.url}&fit=crop&dpr=1&w=10`}
      />
    )
  } else if (height === "full" && width) {
    return (
      <LazyLoadImage
        threshold={threshold}
        effect="blur"
        alt={image?.alt ?? "Ocafi"}
        srcSet={`
          ${image?.url}&fit=crop&dpr=1&w=${width}&h=${windowSize[0]} 1x,
          ${image?.url}&fit=crop&dpr=2&w=${width}&h=${windowSize[0]} 2x,
          ${image?.url}&fit=crop&dpr=3&w=${width}&h=${windowSize[0]} 3x,
          ${image?.url}&fit=crop&dpr=4&w=${width}&h=${windowSize[0]} 4x
        `}
        src={image?.url}
        placeholderSrc={`${image?.url}&fit=crop&dpr=1&w=10`}
      />
    )
  } else if (height && width) {
    return (
      <LazyLoadImage
        threshold={threshold}
        effect="blur"
        alt={image?.alt ?? "Ocafi"}
        srcSet={`
          ${image?.url}&fit=crop&dpr=1&w=${width}&h=${height} 1x,
          ${image?.url}&fit=crop&dpr=2&w=${width}&h=${height} 2x,
          ${image?.url}&fit=crop&dpr=3&w=${width}&h=${height} 3x,
          ${image?.url}&fit=crop&dpr=4&w=${width}&h=${height} 4x
        `}
        src={image?.url}
        placeholderSrc={`${image?.url}&fit=crop&dpr=1&w=10`}
      />
    )
  } else {
    return (
      <LazyLoadImage
        threshold={threshold}
        effect="blur"
        alt={image?.alt ?? "Ocafi"}
        srcSet={`
          ${image?.url}&fit=crop&dpr=1&w=${width} 1x,
          ${image?.url}&fit=crop&dpr=2&w=${width} 2x,
          ${image?.url}&fit=crop&dpr=3&w=${width} 3x,
          ${image?.url}&fit=crop&dpr=4&w=${width} 4x
        `}
        src={image?.url}
        placeholderSrc={`${image?.url}&fit=crop&dpr=1&w=10`}
      />
    )
  }
}

export default Image
